<template>
  <div class="home">
    <homeText></homeText>
  </div>
</template>

<script>
import homeText from '@/components/homeText.vue'

export default {
  name: 'HomeScreen',
  components: {
    homeText
  }
}
</script>

<template>
  <div class="wc-button">
    <router-link :to="to" v-text="text"></router-link>
  </div>
</template>

<script>
export default {
  props: ['text', 'to'],
  name: "greenButton"
}
</script>

<style scoped>
  .wc-button a{
    @apply flex bg-green px-[20px] py-[10px] xs:px-[10px] border-2 border-white rounded-[8px] text-white font-bold uppercase
    lg:border-4 lg:text-[20px] lg:leading-[26px] lg:px-[20px]
    shadow hover:shadow-xl shadow-green transition-shadow duration-500 ease-in-out;
  }
  .wc-button a:hover {
    box-shadow: 0px 0px 30px #00B453;
  }

</style>

<template>
  <div class="wc-text">
    <div>
      <p>Ровно 61 год назад Юрий Гагарин стал первым человеком в космосе. Не очень ровно 24 года назад на орбите начала свою работу Международная космическая станция.
      </p>
      <p>
        67 экспедиций, больше 8500 суток вне Земли и несчётное количество экспериментов, материалов и пользы для всего человечества, вне зависимости от расы, национальности, вероисповедания и прочих взглядов. Но все легенды когда-то заканчиваются, в обозримом будущем МКС заменит новая станция.
      </p>
      <p>
        Какой она будет — пока не знаем, но никто не мешает мечтать. Мы придумали несколько вариантов новой орбитальной станции, чтобы вы могли собрать свою собственную.
      </p>
    </div>
    <div class="wc-btn-go">
      <btn text="Вперёд, к звёздам!" to="constructor"></btn>
    </div>
  </div>
</template>

<script>
import btn from "./greenButton.vue";

export default {
  name: "homeText",
  components: {
    btn
  }
}
</script>

<style scoped>
  .wc-text {
    @apply bg-darkBlue border-[3px] lg:border-[8px] border-borderColor border-solid p-[8px] xxs:my-[35px] xxs:mx-[30px] xs:my-[10px] xs:mx-[80px]
    lg:mt-[75px] lg:mx-[115px] lg:px-[20px] lg:pt-[20px]
    xl:mt-[115px] xl:mx-[265px]
    flex flex-col rounded-[8px];
  }
  p {
    @apply text-white text-[14px] lg:text-[20px] font-medium leading-[17px] xxs:pb-[15px] xs:pb-[18px] text-left
    lg:leading-[26px] lg:pb-[26px];
  }
  p:last-child{
    @apply xs:pb-[22px];
  }
  .wc-btn-go {
    @apply xs:mb-[15px] lg:mt-[18px] lg:mb-[20px] flex justify-center;
  }

  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .wc-text {
      @apply mt-[120px];
    }

    .wc-btn-go{
      @apply mt-[20px];
    }



  }
</style>

<template>
  <div class="wc-wrap">
    <div class="wc-stars" :style="starStyle">
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
      <star width="32" height="32"></star>
    </div>



    <div class="wc-content">
      <div class="wc-header">
        <a href="/">
        <svg class="wc-logo" viewBox="0 0 109 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M28.5646 30.0616C28.08 30.0616 27.6905 29.9611 27.4126 29.7005C27.1348 29.4399 27.0071 29.0774 27.0071 28.6142V17.7169C27.0071 16.2693 26.7728 15.2027 26.3041 14.5171C25.8356 13.8345 25.0892 13.4917 24.0421 13.4917C22.9952 13.4917 22.294 13.8345 21.8464 14.5171C21.3988 15.1997 21.1856 16.2663 21.1856 17.694V28.5897C21.1856 29.0469 21.0579 29.4142 20.7801 29.6762C20.502 29.9382 20.1434 30.0342 19.6491 30.0342H15.0651C14.5804 30.0342 14.1668 29.9169 13.8889 29.6548C13.611 29.3928 13.4834 29.0317 13.4834 28.57V17.6772C13.4834 16.2297 13.2491 15.1631 12.7805 14.4775C12.3119 13.7934 11.5655 13.452 10.5185 13.452C9.47146 13.452 8.77024 13.7934 8.34369 14.4775C7.91714 15.1616 7.69739 16.2267 7.69739 17.6544V28.5517C7.69739 29.0087 7.56813 29.376 7.29184 29.6366C7.14002 29.7767 6.95963 29.8863 6.76163 29.9588C6.56363 30.0314 6.35211 30.0653 6.1398 30.0586H1.55598C1.34253 30.0619 1.13054 30.0245 0.932836 29.9487C0.735127 29.8726 0.555763 29.7597 0.405551 29.6168C0.127645 29.3562 0 28.9935 0 28.5318V16.8924C0 14.2393 0.852582 12.1285 2.55773 10.5601C4.26287 8.99173 6.73601 8.20755 9.97717 8.20755C11.5233 8.18583 13.0521 8.51672 14.4334 9.17204C15.7567 9.81503 16.7358 10.6805 17.3756 11.7455C18.0366 10.679 19.0399 9.81503 20.3615 9.17204C21.7282 8.51902 23.2423 8.18807 24.7741 8.20755C28.0364 8.20755 30.51 8.99072 32.2145 10.5601C33.9192 12.1295 34.7739 14.2398 34.7739 16.8924V28.5349C34.7739 28.9921 34.6463 29.3592 34.3684 29.6197C34.2262 29.7623 34.054 29.8753 33.8628 29.9513C33.6717 30.0273 33.4658 30.065 33.2583 30.0616H28.5646Z" fill="#E52F42"/>
          <path d="M52.1387 16.6752C52.1387 15.3678 51.7768 14.4232 51.0723 13.8396C50.3679 13.256 49.3032 12.9757 47.8748 12.9757C46.0199 12.9757 43.9727 13.4785 41.735 14.4628C40.7963 14.8848 40.0724 14.6837 39.5813 13.8792L38.6861 12.4317C38.153 11.548 38.3452 10.8837 39.2823 10.422C42.1543 8.97459 45.365 8.22816 48.6213 8.25074C52.373 8.25074 55.1871 9.01257 57.0635 10.5362C58.9393 12.0599 59.8781 14.2159 59.8781 16.9708V21.9988C59.8781 24.7932 58.9184 26.9249 57.0005 28.3724C55.0826 29.82 52.2664 30.5452 48.5566 30.5452C44.9536 30.5452 42.1825 29.9205 40.242 28.6542C38.3016 27.3881 37.3208 25.8187 37.3208 23.9492V23.0045C37.3208 21.2157 38.1528 19.7681 39.7946 18.6422C41.4362 17.5162 43.7596 16.9525 46.7422 16.9525H52.1566V16.6477L52.1387 16.6752ZM44.9972 23.4707C44.9939 23.8038 45.0755 24.1326 45.2349 24.4299C45.3942 24.727 45.6269 24.9838 45.9133 25.1788C46.5322 25.6359 47.4272 25.8629 48.6002 25.8629C49.7087 25.8629 50.5828 25.5415 51.2017 24.8985C51.8204 24.2555 52.1387 23.3504 52.1387 22.2031V20.7159H48.2158C47.1929 20.7159 46.4045 20.8972 45.8488 21.2782C45.5754 21.4578 45.3543 21.6993 45.2055 21.9804C45.0569 22.2615 44.9851 22.5731 44.9972 22.8872V23.4707Z" fill="#E52F42"/>
          <path d="M77.7673 17.9788C80.0293 18.6416 81.6257 19.4065 82.5418 20.2901C83.458 21.1739 83.9281 22.3624 83.9281 23.8693C83.9281 25.9811 82.9683 27.6084 81.0293 28.7755C79.0905 29.9427 76.4666 30.5247 73.1398 30.5247C69.7397 30.5351 66.3856 29.7841 63.3533 28.3336C62.4365 27.9101 62.2239 27.2462 62.7151 26.3421L63.7378 24.4924C64.2063 23.6087 64.9318 23.4076 65.8917 23.8693C68.6383 25.1765 71.0297 25.8195 73.0542 25.8195C74.0575 25.8195 74.8461 25.6383 75.4003 25.2772C75.662 25.1166 75.8759 24.8954 76.0216 24.6348C76.1672 24.3743 76.2397 24.083 76.2323 23.7885C76.2323 22.9444 75.5861 22.3213 74.3129 21.9388L68.7693 20.2505C67.7104 19.941 66.6773 19.558 65.6783 19.1048C64.9702 18.7948 64.333 18.3573 63.8025 17.8173C63.385 17.3872 63.0656 16.8811 62.8637 16.3301C62.6805 15.7427 62.5943 15.1322 62.6083 14.52C62.6083 12.4905 63.5245 10.9211 65.3373 9.81489C67.1502 8.7087 69.6643 8.17236 72.8862 8.17236C75.8074 8.17236 78.7706 8.83669 81.7726 10.144C82.7323 10.5655 82.9673 11.2288 82.4771 12.1339L81.5837 13.81C81.1139 14.6561 80.4101 14.8775 79.4719 14.4743C76.9789 13.429 74.825 12.9064 72.9912 12.9064C71.2138 12.9064 70.3252 13.4687 70.3252 14.5947C70.3252 15.3992 70.9441 15.9827 72.159 16.3241L77.7882 17.9726L77.7673 17.9788Z" fill="#E52F42"/>
          <path d="M102.454 30.0615C101.969 30.0615 101.457 29.9183 101.179 29.6562C100.901 29.3942 100.769 28.7877 100.769 28.326V18.4815C100.769 16.7323 100.513 15.4657 100.002 14.6815C99.491 13.8968 98.5943 13.5144 97.2935 13.5144C96.6483 13.5208 96.0165 13.6874 95.4613 13.9974C94.8768 14.2991 94.4125 14.7718 94.1381 15.3443V28.6003C94.1381 29.0574 94.0104 29.4245 93.7341 29.6867C93.4578 29.9488 93.0731 30.0676 92.5821 30.0676H87.9982C87.7875 30.0777 87.577 30.0472 87.3792 29.978C87.1814 29.9088 87.0006 29.8024 86.8479 29.6654C86.5698 29.4048 86.4423 29.0421 86.4423 28.5805V1.49258C86.426 1.29332 86.4558 1.09312 86.5292 0.90568C86.6027 0.718239 86.7183 0.547989 86.8681 0.406606C87.0178 0.265223 87.1982 0.156044 87.397 0.0865446C87.5956 0.0170453 87.8079 -0.0111261 88.0192 0.00395334H92.5982C93.083 0.00395334 93.4738 0.124325 93.7502 0.386397C93.8957 0.530521 94.0083 0.701003 94.0817 0.887497C94.155 1.07399 94.1874 1.2726 94.1768 1.47126V10.2781C95.6902 8.91085 97.5451 8.22724 99.7414 8.22724C102.488 8.22724 104.645 9.05154 106.222 10.7002C107.799 12.3488 108.589 14.6617 108.589 17.6375V28.5348C108.589 28.9919 108.462 29.3591 108.184 29.6197C108.033 29.7628 107.854 29.8755 107.656 29.9515C107.459 30.0274 107.247 30.0649 107.033 30.0615H102.454Z" fill="#E52F42"/>
        </svg>
        </a>

        <div class="wc-social" v-if="!isInConstructor">
          <a target="_blank" href="https://vk.com/share.php?url=https://space-station.mash.ru" class="bg-vk">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.99966 1.99942C0.200195 3.79888 0.200195 6.69544 0.200195 12.488V13.5119C0.200195 19.3045 0.200195 22.201 1.99966 24.0005C3.79913 25.7999 6.69568 25.7999 12.4882 25.7999H13.5122C19.3047 25.7999 22.2013 25.7999 24.0007 24.0005C25.8002 22.201 25.8002 19.3045 25.8002 13.5119V12.488C25.8002 6.69544 25.8002 3.79888 24.0007 1.99942C22.2013 0.199951 19.3047 0.199951 13.5122 0.199951H12.4882C6.69568 0.199951 3.79913 0.199951 1.99966 1.99942ZM4.5202 7.98664H7.44288C7.53888 12.872 9.69351 14.9413 11.4002 15.368V7.98664H14.1522V12.2C15.8375 12.0186 17.6082 10.0986 18.2055 7.98664H20.9575C20.7324 9.08195 20.2836 10.119 19.6394 11.033C18.9952 11.947 18.1694 12.7183 17.2135 13.2986C18.2808 13.8285 19.2234 14.5788 19.9792 15.5C20.7349 16.4213 21.2865 17.4924 21.5975 18.6426H18.5682C18.2891 17.6435 17.721 16.7491 16.9353 16.0716C16.1497 15.3942 15.1815 14.9638 14.1522 14.8346V18.6426H13.8215C7.98688 18.6426 4.65886 14.6426 4.5202 7.98664Z" fill="#F5FBFE"/>
            </svg>
          </a>
          <a target="_blank" href="https://telegram.me/share/url?url=https://space-station.mash.ru&text=%D0%A1%D0%BB%D0%B5%D0%B4%D1%83%D1%8E%D1%89%D0%B0%D1%8F%20%D1%81%D1%82%D0%B0%D0%BD%D1%86%D0%B8%D1%8F%20-%20%D0%BA%D0%BE%D1%81%D0%BC%D0%BE%D1%81" class="bg-tg">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.0004 0.399902C6.4893 0.399902 0.400391 6.48881 0.400391 13.9999C0.400391 21.511 6.4893 27.5999 14.0004 27.5999C21.5115 27.5999 27.6004 21.511 27.6004 13.9999C27.6004 6.48881 21.5115 0.399902 14.0004 0.399902ZM20.3042 9.649C20.0996 11.7995 19.214 17.0181 18.7632 19.4267C18.5726 20.4459 18.1972 20.7876 17.8338 20.821C17.0442 20.8937 16.4443 20.2991 15.6794 19.7977C14.4824 19.013 13.8062 18.5246 12.6443 17.7589C11.3016 16.8741 12.172 16.388 12.9372 15.5928C13.1375 15.3848 16.6173 12.2197 16.6846 11.9325C16.693 11.8966 16.7011 11.7625 16.6214 11.6921C16.5416 11.6217 16.4245 11.6456 16.3398 11.6647C16.2197 11.692 14.3075 12.9559 10.6032 15.4564C10.0605 15.829 9.56886 16.0106 9.12836 16.0011C8.64276 15.9907 7.70869 15.7266 7.01427 15.5008C6.16257 15.224 5.48564 15.0776 5.54459 14.6075C5.5753 14.3625 5.91256 14.1121 6.55636 13.8562C10.5211 12.1288 13.1648 10.99 14.4876 10.4397C18.2644 8.86875 19.0492 8.59588 19.5608 8.58677C19.6733 8.58491 19.9248 8.61277 20.0878 8.74498C20.1961 8.83917 20.2652 8.9706 20.2813 9.11328C20.309 9.29044 20.3166 9.47013 20.3042 9.649Z" fill="#F5FBFE"/>
            </svg>
          </a>
        </div>

      </div>

      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>

    </div>
  </div>
</template>

<script>
//import constructorScreen from './components/constructor.vue'
import star from './components/star.vue'

/*
function pulse() {
  let stars =  Array.prototype.slice.call(document.getElementsByClassName("star"));
  for (let p = 0; p < stars.length; p++) {
    setTimeout(function(){
      stars[p].classList.add('animate-pulse');
    }, Math.floor(Math.random() * 3));
  }
}
*/

export default {
  name: 'App',
  components: {
    star
  },
  created() {

    /*screen.orientation.lock("landscape")
        .then(function() {
          console.log('Locked');
        })
        .catch(function(error) {
          console.error(error);
        });*/
  },
  mounted(){
    //pulse();
  },
  computed: {
    isInConstructor() {
      return this.$route.path === "/constructor" || this.$route.path.startsWith("/go");
    },
    starStyle(){
      return 'opacity:'+(this.isInConstructor?'0.5':'1')+';';
    }
  }
}
</script>

<style>
#app {
  font-family: 'Source Code Pro', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  @apply bg-darkBlue relative py-[7px] px-[15px] min-w-full min-h-screen lg:pt-[17px] overflow-hidden
  lg:px-[19px]
  xl:px-[50px] xl:pt-[17px];
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.wc-header {
  @apply flex flex-row justify-between relative w-full;
}

.wc-social {
  @apply flex flex-col items-center justify-center text-[#F5FBFE] gap-[10px] absolute right-0 top-[3px]
    lg:flex-row;
}

.wc-social a{
  @apply flex rounded-full w-[32px] h-[32px] overflow-hidden lg:w-[40px] lg:h-[40px];
}

.wc-social a svg {
  @apply w-full h-full;
}

.wc-wrap {
  @apply flex flex-col items-start justify-center;
}
.wc-logo {
  @apply w-[59px] h-[16px] lg:w-[109px] lg:h-[31px]
}
.wc-stars {
  @apply absolute inset-0 w-full h-full text-starColor z-0 /*flex flex-wrap*/;
}
.wc-gray {
  @apply absolute inset-0 w-full h-full z-10
}
.wc-content {
  @apply z-20 w-full;
}
.wc-stars svg {
  @apply w-[32px] h-[32px]
}

.wc-stars svg:nth-child(1){
  @apply left-[50px] top-[334px]
  xl:left-[64px] xl:top-[686px] xl:w-[60px] xl:h-[60px]
  2xl:left-[86px] 2xl:top-[926px] 2xl:w-[60px] 2xl:h-[60px];
}

.wc-stars svg:nth-child(2){
  @apply left-[63px] top-[50px]
  xl:left-[247px] xl:top-[554px] xl:w-[50px] xl:h-[50px]
  2xl:left-[333px] 2xl:top-[747px] 2xl:w-[50px] 2xl:h-[50px];
}

.wc-stars svg:nth-child(3){
  @apply left-[220px] top-[16px] w-[24px] h-[24px]
  xl:left-[120px] xl:top-[385px] xl:w-[50px] xl:h-[50px]
  2xl:left-[162px] 2xl:top-[519px] 2xl:w-[50px] 2xl:h-[50px];
}

.wc-stars svg:nth-child(4){
  @apply left-[78px] top-[174px]  w-[24px] h-[24px]
  xl:left-[95px] xl:top-[160px] xl:w-[50px] xl:h-[50px]
  2xl:left-[128px] 2xl:top-[216px] 2xl:w-[50px] 2xl:h-[50px];
}

.wc-stars svg:nth-child(5){
  @apply left-[366px] top-[7px] w-[24px] h-[24px]
  xl:left-[390px] xl:top-[83px] xl:w-[40px] xl:h-[40px]
  2xl:left-[526px] 2xl:top-[112px] 2xl:w-[40px] 2xl:h-[40px];
}

.wc-stars svg:nth-child(6){
  @apply left-[660px] top-[17px]
  xl:left-[666px] xl:top-[66px] xl:w-[50px] xl:h-[50px]
  2xl:left-[899px] 2xl:top-[89px] 2xl:w-[50px] 2xl:h-[50px];
}

.wc-stars svg:nth-child(7){
  @apply left-[695px] top-[150px]
  xl:left-[1220px] xl:top-[90px] xl:w-[60px] xl:h-[60px]
  2xl:left-[1647px] 2xl:top-[121px] 2xl:w-[60px] 2xl:h-[60px];
}

.wc-stars svg:nth-child(8){
  @apply left-[730px] top-[312px]
  xl:left-[1290px] xl:top-[340px] xl:w-[60px] xl:h-[60px]
  2xl:left-[1741px] 2xl:top-[459px] 2xl:w-[60px] 2xl:h-[60px];
}

.wc-stars svg:nth-child(9){
  @apply left-[63px] top-[50px]
  xl:left-[1145px] xl:top-[630px] xl:w-[60px] xl:h-[60px]
  2xl:left-[1545px] 2xl:top-[850px] 2xl:w-[60px] 2xl:h-[60px];
}

.wc-stars svg:nth-child(10){
  @apply left-[63px] top-[50px]
  xl:left-[1360px] xl:top-[650px] xl:w-[60px] xl:h-[60px]
  2xl:left-[1836px] 2xl:top-[877px] 2xl:w-[60px] 2xl:h-[60px];
}

.wc-stars svg:nth-child(11){
  @apply left-[63px] top-[50px]
  xl:left-[617px] xl:top-[655px] xl:w-[60px] xl:h-[60px]
  2xl:left-[832px] 2xl:top-[884px] 2xl:w-[60px] 2xl:h-[60px];
}

.wc-stars svg:nth-child(3n){
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
}

.wc-stars svg:nth-child(2n){
  animation: pulse 3s cubic-bezier(.4,0,.6,1) infinite;
}

.wc-stars svg:nth-child(4n){
  animation: pulse 5s cubic-bezier(.4,0,.6,1) infinite;
}
.wc-stars svg:first-child{
  animation: pulse 8s cubic-bezier(.4,0,.6,1) infinite;
}
.wc-stars svg:nth-child(11n){
  animation: pulse 6s cubic-bezier(.4,0,.6,1) infinite;
}
</style>

<template>
  <svg class="star " :style="style"  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0L18.6587 13.3413L32 16L18.6587 18.6587L16 32L13.3413 18.6587L0 16L13.3413 13.3413L16 0Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  props: ["width", "height", "top", "left"],
  name: "starItem",
  computed: {
    style () {
      return 'position:absolute; top:' + this.top + ';' + 'left: '+ this.left
    }
  },
  mounted(){
    //animate-pulse
  }
}
</script>

<style scoped>

</style>
